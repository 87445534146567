import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import { MatSelectModule } from '@angular/material';
import swal from 'sweetalert';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html'
})
export class OrderAddComponent implements OnInit {

  data: any = [];
  dataNotFound = false
  reqSent = false;

  constructor(private _location: Location, public db: DatabaseService, public actRoute: ActivatedRoute) {
    if (localStorage.getItem('loginType') == 'user') { this.getCust(); }

    this.getOrderNo();
    this.form.orderItem = [];
    this.form.basicData = {
      net_amt: null,
      total_qty: null,
      total_amt: null,
      tax_amt: null,
      discount_amt: null,
      orderItem: null,
      customer_id: null,
      order_no: null,
      order_date: moment(new Date()).format('YYYY-MM-DD'),
      delivery_date: moment(new Date()).format('YYYY-MM-DD'),
      payment_date: moment(new Date()).format('YYYY-MM-DD')
    };
    this.form.product_detail = {
      stock_qty: null,
      order_qty: null,
      mop: null,
      discount: null,
      discount_amt: null,
      cgst_tax: 9,
      sgst_tax: 9,
      tax: 18,
      tax_amt: null,
      total_amt: null,
      sub_total: null
    };
    if (localStorage.getItem('loginType') == 'dealer') { this.form.basicData.customer_id = JSON.parse(localStorage.getItem('karbitUserData')).id }

    if (this.actRoute.snapshot.params.id) {
      this.editHandler(this.actRoute.snapshot.params.id)
    }
  }
  drType: any = [
    { id: 1, name: "Distributor", slug: "distributor" },
    { id: 2, name: "Dealer", slug: "dealer" },
    { id: 3, name: "Site", slug: "Site" },
    { id: 4, name: "Architect", slug: "architect" },
    { id: 5, name: "Other", slug: "other" },
  ]
  form: any = {};
  ngOnInit() {
    this.data = new Array(7);
    this.getCategory();
    this.getofferlist();
  }

  backClicked() {
    this._location.back();
  }
  filter: any = {};
  custData: any = []
  filteredCustData: any = []
  offerlist: any = []


  getofferlist() {
    let reqData = {
      limit: 10,
      start: 0,
      filter: { "applicable_for_name": "order", "applicale_for": 6 }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'offer/list').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.offerlist = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  custtypesite: any = [];
  getCust() {
    // console.log("Cust Type : -- ", this.form.dr_type)

    let reqData = {
      limit: 10000,
      start: 0,
      filter: { company_name: this.filter.company_name, cust_type_id: this.form.dr_type }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'order/dr_list').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.custData = resp['result'].data;
        this.custtypesite = this.custData[0].cust_type
        this.filteredCustData = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  productsData: any = []
  categoryData: any = []
  filteredProductsData: any = []
  getCategory() {
    // console.log("getCategory");

    let reqData = {
      limit: 100000,
      start: 0,
      filter: {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'master/product_category').subscribe(async resp => {
      if (resp['status'] == 'success') {
        this.categoryData = resp['result'].data;
        console.log("this.categoryData", this.categoryData)
        this.filteredProductsData = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  getProducts(product_category: any) {
    // console.log("product_list")
    let reqData = {
      limit: 100000,
      start: 0,
      filter: { product_category: product_category }
    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'order/product_list').subscribe(async resp => {
      if (resp['status'] == 'success') {
        this.productsData = resp['result'].data;
        this.filteredProductsData = resp['result'].data;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  getOrderNo() {
    let reqData = {

    }
    this.db.presentLoader();
    this.db.postReq(reqData, 'order/get_order_no').subscribe(resp => {
      if (resp['status'] == 'success') {
        this.form.basicData.order_no = resp['result'].data.order_no;
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {

    })
  }
  paymentDateHandler() {
    let index = this.custData.findIndex(r => (r.id === this.form.basicData.customer_id))
    if (index != -1) {
      this.form.basicData.payment_date = moment(new Date()).add(this.custData[index].credit_day, 'days').format("YYYY-MM-DD");
    }
  }
  calcAggtotal() {
    console.log("calcAggtotal")
    this.form.orderItem.map(r => {
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt) + parseFloat(r.total_amt);
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2);
    });
    // console.log("calcAggtotal", this.form.basicData.total_amt)
  }
  calc() {
    if (this.form.product_detail.product_id && this.form.basicData.customer_id) {
      this.db.postReq({ product_id: this.form.product_detail.product_id, customer_id: this.form.basicData.customer_id }, 'order/dr_product_discount').subscribe(resp => {
        let product_detail = this.productsData.filter(r => r.id === this.form.product_detail.product_id)[0]
        // console.log("prodctDetail", product_detail)
        let user = this.custData.filter(r => r.id === this.form.basicData.customer_id)[0]
        this.form.product_detail.basic_price = product_detail.basic_price;
        this.form.product_detail.mop = product_detail.mop;
        this.form.product_detail.mrp = product_detail.mrp;
        this.form.product_detail.product_name = product_detail.product_name;
        this.form.product_detail.product_category = product_detail.product_category;
        this.form.product_detail.product_code = product_detail.product_code;
        this.form.product_detail.customer_id = this.form.customer_id;
        this.form.product_detail.tax = 18;
        this.form.product_detail.sgst_tax = 9;
        this.form.product_detail.cgst_tax = 9;
        this.form.product_detail.cgst_tax_amt = product_detail.basic_price * (this.form.product_detail.cgst_tax / 100);
        this.form.product_detail.sgst_tax_amt = product_detail.basic_price * (this.form.product_detail.sgst_tax / 100);
        // Calculate offer_price based on discount and round it down
this.form.product_detail.offer_price = (this.form.product_detail.discount && this.form.product_detail.discount > 0)
? this.form.product_detail.mop - ((this.form.product_detail.mop * this.form.product_detail.discount) / 100)
: this.form.product_detail.mop;
this.form.product_detail.offer_price = Math.floor(this.form.product_detail.offer_price);

// Logging calculated offer_price
console.log('Offer Price:', this.form.product_detail.offer_price);
console.log('MOP:', this.form.product_detail.mop);
console.log('Order Quantity:', this.form.product_detail.order_qty);

// Calculate net_amt based on whether offer_price is available
if (this.form.product_detail.offer_price == null || this.form.product_detail.offer_price === undefined || this.form.product_detail.offer_price === this.form.product_detail.mop) {
// If offer_price is null or undefined, use mop
this.form.product_detail.net_amt = this.form.product_detail.mop * this.form.product_detail.order_qty;
console.log('Using MOP for Net Amount calculation');
} else {
// If offer_price is available, use offer_price
this.form.product_detail.net_amt = this.form.product_detail.offer_price * this.form.product_detail.order_qty;
console.log('Using Offer Price for Net Amount calculation');
}

// Logging the resulting net amount
console.log('Net Amount:', this.form.product_detail.net_amt);


        this.form.product_detail.discount_amt = ((this.form.product_detail.net_amt / 100) * this.form.product_detail.discount)
        this.form.product_detail.sub_total = this.form.product_detail.net_amt
        this.form.product_detail.total_on_mrp = (product_detail.mrp * this.form.product_detail.order_qty)


        if (this.form.product_detail.discount_amt) {
          this.form.product_detail.savings = this.form.product_detail.total_on_mrp - this.form.product_detail.sub_total
          console.log("TOTAL ON MRP::--", this.form.product_detail.total_on_mrp)
          console.log("TOTAL After basic discount::--", this.form.product_detail.sub_total)
          console.log("if ---  savings:-- ", this.form.product_detail.savings)
          console.log("\n")
        }
        else {
          this.form.product_detail.savings = this.form.product_detail.total_on_mrp - this.form.product_detail.net_amt
          console.log("TOTAL ON MRP::--", this.form.product_detail.total_on_mrp)
          console.log("TOTAL without any discount::--", this.form.product_detail.net_amt)
          console.log("else ----  savings:-- ", this.form.product_detail.savings)
          console.log("\n")
        }

        this.form.product_detail.tax_amt = (product_detail.basic_price * this.form.product_detail.order_qty) * (this.form.product_detail.tax / 100)
        this.form.product_detail.total_amt = this.form.product_detail.net_amt
        console.log("Total Amount Final",this.form.product_detail.total_amt)
        this.form.product_detail.net_amt = parseFloat(this.form.product_detail.net_amt).toFixed(2)
        this.form.product_detail.sub_total = parseFloat(this.form.product_detail.sub_total).toFixed(2)
        this.form.product_detail.discount_amt = parseFloat(this.form.product_detail.discount_amt).toFixed(2)
        this.form.product_detail.sgst_tax_amt = (this.form.product_detail.sgst_tax_amt).toFixed(2) * this.form.product_detail.order_qty;
        this.form.product_detail.cgst_tax_amt = (this.form.product_detail.cgst_tax_amt).toFixed(2) * this.form.product_detail.order_qty
        this.form.product_detail.tax_amt = parseFloat(this.form.product_detail.tax_amt).toFixed(2)
        this.form.product_detail.total_amt = parseFloat(this.form.product_detail.total_amt).toFixed(2)
      })

    } else {
      this.form.product_detail = {
        stock_qty: null,
        order_qty: null,
        mop: null,
        product_category: null,
        discount: null,
        ad_discount: null,
        discount_amt: null,
        tax: null,
        tax_amt: null,
        total_amt: null,
        sub_total: null
      };
    }
  }
  addToList() {
    if (!this.form.product_detail.product_id) {
      this.db.presentAlert('Error', 'Please select product first!');
      return;
    }
    if (!this.form.product_detail.order_qty || this.form.product_detail.order_qty < 0) {
      this.db.presentAlert('Error', 'Please enter a valid quantity!');
      return;
    }

    let i = this.form.orderItem.findIndex(r => r.product_id === this.form.product_detail.product_id)
    if (i != -1) {
      this.form.orderItem.splice(i, 1)
    }
    this.form.orderItem.push(this.form.product_detail);
    this.form.basicData.net_amt = 0;
    this.form.basicData.total_qty = 0;
    this.form.basicData.total_amt = 0;
    this.form.basicData.tax_amt = 0;
    this.form.basicData.discount_amt = 0;
    this.form.basicData.sub_total = 0;
    this.form.basicData.ad_discount = 0;
    this.form.basicData.basic_discount = 0;
    console.log("this.form.orderItem", this.form.orderItem)
    this.form.orderItem.map((r, i) => {
      this.form.basicData.total_qty = parseFloat(this.form.basicData.total_qty) + parseInt(r.order_qty)
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt) + parseFloat(r.net_amt)
      // console.log("r.net_amt",r.net_amt)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt) + parseFloat(r.total_amt)
      // this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt) + (parseFloat(r.total_tax) || parseFloat(r.tax_amt))
      // console.log("this.form.basicData.tax_amt before if",this.form.basicData.tax_amt)

      if (r.total_tax) {
        this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt) + parseFloat(r.total_tax);
        // console.log("r.total_tax:---- ", r.total_tax);
        // console.log("r.tax_amt:---- ", r.tax_amt);
        // console.log("this.form.basicData.tax_amt inside if", this.form.basicData.tax_amt);
      } else {
        this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt) + parseFloat(r.tax_amt);
        // console.log("r.tax_amt:---- ", r.tax_amt);
        // console.log("r.total_tax:---- ", r.total_tax);

        // If r.total_tax is undefined, assign the value of r.tax_amt to r.total_tax
        if (r.total_tax === undefined) {
          r.total_tax = r.tax_amt;
          // console.log("r.total_tax (after assignment):---- ", r.total_tax);
        }

        // console.log("this.form.basicData.tax_amt inside else", this.form.basicData.tax_amt);
      }


      // console.log("this.form.orderItem second:--", this.form.orderItem)

      this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt) + parseFloat(r.discount_amt)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total) + parseFloat(r.sub_total)
      this.form.basicData.ad_discount = parseFloat(this.form.basicData.ad_discount)
      this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt).toFixed(2)
      // console.log("this.form.basicData.net_amt second console:--",this.form.basicData.net_amt)
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2) || this.form.orderItem[i].total_amt
      this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt).toFixed(2)
      // console.log("this.form.basicData.tax_amt Final:--",this.form.basicData.tax_amt)
      this.form.basicData.basic_discount += parseFloat(this.form.basicData.discount_amt).toFixed(2)
      this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total).toFixed(2)
      r.customer_id = this.form.basicData.customer_id;
    });
    this.form.basicData.total_item = this.form.orderItem.length;

    this.form.product_detail = {
      stock_qty: null,
      order_qty: null,
      mop: null,
      discount: null,
      discount_amt: null,
      ad_discount: null,
      tax: null,
      tax_amt: null,
      total_amt: null,
      sub_total: null,
    };
  }
  disc_amt: number = 0;
  appliedOffer: any = null;
  addOfferDiscount(selectedOffer: any) {
    // If an offer is already applied, remove its discount
    if (this.appliedOffer) {
      let previousDiscount = this.appliedOffer.offer_disc / 100;
      let previousDiscountAmt = this.form.basicData.total_amt * previousDiscount;
      this.form.basicData.total_amt += this.disc_amt; // Add back the previously subtracted discount
    }

    // Apply the new offer's discount
    let newDiscount = selectedOffer.offer_disc / 100;
    this.disc_amt = parseFloat((this.form.basicData.total_amt * newDiscount).toFixed(2));
    this.form.basicData.total_amt -= this.disc_amt;
    this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt.toFixed(2))
    this.form.basicData.offer_code = selectedOffer.code;
    this.form.basicData.offer_discount = selectedOffer.offer_disc;
    this.form.basicData.offer_disc_amt = this.disc_amt
    console.log("orderitem", this.form.orderItem)
    this.form.orderItem.map((val, index) => {


      
      let offerDiscOnItem = val.total_amt * newDiscount;

// Log the initial offer price
console.log("Initial Offer Price:", val.offer_price);
console.log("Offer Disc %",selectedOffer.offer_disc)

// Calculate the new offer price after applying the selected offer discount
val.offer_price = val.offer_price - (val.offer_price * selectedOffer.offer_disc) / 100;
val.offer_price = Math.floor(val.offer_price)

// Log the updated offer price
console.log("Updated Offer Price after Discount:", val.offer_price);

// Calculate the new total amount based on the updated offer price
let newTotalAmt = val.order_qty * val.offer_price;
console.log("Total qty:", val.order_qty);
console.log("New Total Amount:", newTotalAmt);
console.log("\n")

// Update the total amount with the new calculated value
val.total_amt = newTotalAmt;

// Round down the total amount
// val.total_amt = Math.floor(val.total_amt);

// Log the values to the console
// console.log("New Discount on Item:", offerDiscOnItem);


      // console.log("Offer Price Before OFFER", val.offer_price)
      // val.offer_price -= offerDiscOnItem / val.order_qty
      // val.offer_price = Math.floor(val.offer_price)
      // console.log("Total Quantity ", val.order_qty)
      // console.log("OFFER DISCOUNT ON EACH ", offerDiscOnItem)
      // console.log("Offer Price After OFFER", val.offer_price)

      // Initialize savings for basicData if not already set
      if (!this.form.basicData.savings) {
        this.form.basicData.savings = 0;
      }

      if (offerDiscOnItem) {
        val.savings = (val.total_on_mrp - Math.floor(val.total_amt));
        console.log(":INSIDE IF");
        console.log("MRP TOTAL:--", val.total_on_mrp);
        console.log("TOTAL AMT AFTER OFFER:--", val.total_amt);
        console.log("Savings After Applying Offer:--", val.savings);
      }
      console.log("\n");
    })
    // Update the appliedOffer with the new one
    this.appliedOffer = selectedOffer;
  }

  // addOfferDiscount(selectedOffer: any) {
  //   // If an offer is already applied, remove its discount
  //   if (this.appliedOffer) {
  //     let previousDiscount = this.appliedOffer.offer_disc / 100;
  //     let previousDiscountAmt = this.form.basicData.total_amt * previousDiscount;
  //     this.form.basicData.total_amt += this.disc_amt; // Add back the previously subtracted discount
  //   }

  //   // Apply the new offer's discount
  //   let newDiscount = selectedOffer.offer_disc / 100;
  //   this.disc_amt = this.form.basicData.total_amt * newDiscount;
  //   this.form.basicData.total_amt -= this.disc_amt;
  //   this.form.basicData.offer_code = selectedOffer.code;
  //   this.form.basicData.offer_discount = selectedOffer.offer_disc;
  //   this.form.basicData.offer_disc_amt = this.disc_amt
  //   this.form.orderItem.map((val, index) => {
  //     let offerDiscOnItem = val.total_amt * newDiscount;
  //     let newTotalAmt = val.total_amt - offerDiscOnItem;
  //     val.total_amt = newTotalAmt;
  //   })

  //   // Update the appliedOffer with the new one
  //   this.appliedOffer = selectedOffer;
  // }


  removeOffer() {
    if (this.appliedOffer) {
      // Revert the previously applied discount
      this.form.basicData.total_amt += this.disc_amt;
      this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt.toFixed(2));

      this.form.orderItem.map((item, index) => {
        // Log the offer price before removing the offer
        console.log(`Offer price before removing offer for item ${index}: ${item.offer_price}`);

        if (this.form.basicData.ad_discount) {
          // Revert total amount to the state with ad_discount
          item.total_amt = item.ad_total;
          item.savings = item.total_on_mrp - item.ad_total;

          // Calculate offer price by considering ad_discount
          item.offer_price = (item.original_offer_price - (item.ad_discount_amt / item.order_qty)).toFixed(2);
          console.log(`Offer price after removing offer but with ad_discount for item ${index}: ${item.offer_price}`);

          console.log("Savings after removing offer when ad_dis:--", item.savings);
          console.log("\n");
        } else {
          // Revert total amount to the original sub_total without any discounts
          item.total_amt = item.sub_total;
          item.savings = item.total_on_mrp - item.sub_total;

          // Revert offer price to its original state without any discounts
          item.offer_price = item.original_offer_price ? parseFloat(item.original_offer_price).toFixed(2) : item.offer_price;
          console.log(`Offer price after removing offer with no ad_discount for item ${index}: ${item.offer_price}`);

          console.log("Savings after removing offer when no ad_dis:--", item.savings);
          console.log("\n");
        }
      });

      // Reset the applied offer and discount amount
      this.appliedOffer = null;
      this.disc_amt = 0;
      this.form.basicData.savings = 0;
      this.form.offer_disc = '';
    }
  }

  // removeOffer() {
  //   if (this.appliedOffer) {
  //     // Revert the previously applied discount
  //     this.form.basicData.total_amt += this.disc_amt;
  //     this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt.toFixed(2))
  //     this.form.orderItem.map((item, index) => {
  //       if (this.form.basicData.ad_discount) {
  //         item.total_amt = item.ad_total;
  //         item.savings = item.total_on_mrp - item.ad_total
  //         console.log("Savings after removing offer when ad_dis:--", item.savings)
  //         console.log("\n")


  //       }

  //       else {
  //         item.total_amt = item.sub_total;
  //         item.savings = item.total_on_mrp - item.sub_total
  //         console.log("Savings after removing offer when no ad_dis:--", item.savings)
  //         console.log("\n")

  //       }

  //     })
  //     // Reset the applied offer and discount amount
  //     this.appliedOffer = null;

  //     this.disc_amt = 0;
  //     this.form.basicData.savings = 0
  //     this.form.offer_disc = '';

  //   }
  // }

  onSubmit() {
    let reqData = this.form
    if (this.orderEditId) {
      reqData.order_id = this.orderEditId;
    }
    if (this.custData.length) {
      var custDataTemp = this.custData.filter(x => x.id == this.form.basicData.customer_id)[0];
      // console.log('custDataTemp', custDataTemp);
      this.form.basicData.customer_name = custDataTemp.company_name
      this.form.basicData.customer_type = custDataTemp.cust_type
    }

    // console.log("reqData", reqData);
    this.db.presentLoader();
    // return
    this.db.postReq(reqData, this.orderEditId ? "order/update" : 'order/add').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        this.db.successAlert(resp['status'], resp['message']);
        this.backClicked();
      } else {
        if (resp['message'] == 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message'])
      }
    }, err => {
      this.db.errDismissLoader();
    })

  }
  removeItem(i) {

    swal({
      title: "Are you sure?",
      text: "You want remove this item!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.form.orderItem.splice(i, 1)

        } else {
          swal("Your data is safe!");
        }
      });
  }
  orderEditId: any
  editHandler(orderId) {
    this.orderEditId = orderId;
    this.db.presentLoader();

    this.db.postReq({ 'order_id': orderId }, 'order/detail').subscribe(resp => {
      this.db.dismissLoader();
      if (resp['status'] == 'success') {
        let orderData = resp['result'];
        // console.log("orderData", orderData);
        this.form.basicData = orderData.data;
        this.form.orderItem = orderData.orderItem;
        this.form.basicData.order_date = moment(this.form.basicData.order_date).format('YYYY-MM-DD');
        this.form.basicData.delivery_date = moment(this.form.basicData.delivery_date).format('YYYY-MM-DD');
        this.form.basicData.payment_date = moment(this.form.basicData.payment_date).format('YYYY-MM-DD');

        // Initialize total_amt and tax_amt
        this.form.basicData.total_amt = 0;
        this.form.basicData.basicDiscount = 0;
        this.form.basicData.tax_amt = 0;  // Ensure tax_amt starts as 0
        this.form.orderItem.forEach(r => {
          r.order_qty = parseInt(r.order_qty);
          // r.net_amt = parseFloat(r.net_amt);
          r.total_amt = parseFloat(r.total_amt.toFixed(2))
          this.form.basicData.total_amt += r.total_amt
          this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt.toFixed(2))
          r.discount_amt = parseFloat(r.discount_amt.toFixed(2));
          this.form.basicData.discount_amt += r.discount_amt

          r.sub_total = r.total_amt
          // console.log("r.sub_total inside edithandler", r.sub_total)

          // Store basic_discount in a component property
          this.form.basicData.basicDiscount += this.form.basicData.discount_amt;
          // console.log("this.form.basicData.basicDiscount before",this.form.basicData.basicDiscount)
          this.form.basicData.basicDiscount = parseFloat(this.form.basicData.basicDiscount.toFixed(2))
          // console.log("this.form.basicData.basicDiscount after",this.form.basicData.basicDiscount)

          let totalTax = parseFloat(r.total_tax);
          totalTax = parseFloat(totalTax.toFixed(2))
          r.total_tax = totalTax
          console.log("r.total_tax", r.total_tax)

          if (!isNaN(totalTax)) {
            this.form.basicData.tax_amt += totalTax;
            this.form.basicData.tax_amt = parseFloat(this.form.basicData.tax_amt.toFixed(2))
          }
          r.discount_amt = parseFloat(r.discount_amt);
        });

        // Ensure tax_amt is a valid number before sending to the backend
        if (isNaN(this.form.basicData.tax_amt)) {
          this.form.basicData.tax_amt = 0;  // Handle NaN by setting to 0
        }
      }
      else {
        if (resp['message'] === 'Invalid token') {
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'], resp['message']);
      }
    }, err => {
      this.db.errHandler(err);
    });
  }

  adDiscountAmt: number = 0;  // Initialize as needed

  applyAdDiscount() {
    // Initialize adDiscountAmt
    this.adDiscountAmt = 0;

    // Check if discount is being removed (i.e., ad_discount is empty or zero)
    const isDiscountRemoved = !this.form.basicData.ad_discount || parseFloat(this.form.basicData.ad_discount) === 0;

    // Loop through each item
    console.log("this.form.orderItem", this.form.orderItem);
    this.form.orderItem.map((item, index) => {
      if (isDiscountRemoved) {
        // Revert to original total amount and offer price if discount is removed
        if (item.original_total_amt) {
          item.total_amt = item.original_total_amt; // Restore original total amount
          console.log(`Offer price before removing ad discount for item ${index}: ${item.offer_price}`);
          item.offer_price = item.original_offer_price; // Restore original offer price
          console.log(`Offer price after removing ad discount for item ${index}: ${item.offer_price}`);

          console.log("Total amount ad_disc removed", item.total_amt);

          item.ad_discount = 0;
          item.ad_discount_amt = 0;
          this.form.basicData.discount_amt -= item.ad_discount_amt; // Adjust basicData.discount_amt

          item.offer_price = item.original_offer_price; // Reset offer price
        }
      } else {
        // Apply the discount if it is present
        if (!item.original_total_amt) {
          item.original_total_amt = item.total_amt; // Store original total amount
        }

        if (!item.original_offer_price) {
          item.original_offer_price = item.offer_price; // Store original offer price
        }

        console.log(`Offer price before applying ad discount for item ${index}: ${item.offer_price}`);

        let adDiscountPercentage = parseFloat(this.form.basicData.ad_discount) || 0;
        let adDiscountAmt = ((item.original_total_amt || 0) * adDiscountPercentage) / 100;
        this.adDiscountAmt += adDiscountAmt; // Accumulate the additional discount amount
        this.form.basicData.discount_amt = this.adDiscountAmt + parseFloat(this.form.basicData.discount_amt || '0');
        item.ad_discount = adDiscountPercentage;
        item.ad_discount_amt = adDiscountAmt;

        // Recalculate the total amount after applying ad_discount
        item.total_amt = (item.original_total_amt || 0) - adDiscountAmt;
        item.total_amt = Math.floor(item.total_amt)
        item.ad_total = item.total_amt;

        item.total_amt = parseFloat(item.total_amt).toFixed(2);
        this.form.basicData.total_amt = item.total_amt;
        console.log("Offer before applying ad discount", item.offer_price);

        item.offer_price -= item.ad_discount_amt / item.order_qty;
        item.offer_price = Math.floor(item.offer_price)
        console.log("Ad discount", item.ad_discount_amt);
        console.log(`Offer price after applying ad discount for item ${index}: ${item.offer_price}`);

        console.log("\n");

        item.savings = item.total_on_mrp - item.total_amt;
        console.log("TOTAL AMT ON MRP:--", item.total_on_mrp);
        console.log("Total After AD-disc:--", item.total_amt);
        console.log("Savings after Apply Ad_Discount:--", item.savings);
        console.log("\n");
      }
    });

    this.recalculateBasicData();
  }


  // applyAdDiscount() {
  //   // Initialize adDiscountAmt
  //   this.adDiscountAmt = 0;

  //   // Check if discount is being removed (i.e., ad_discount is empty or zero)
  //   const isDiscountRemoved = !this.form.basicData.ad_discount || parseFloat(this.form.basicData.ad_discount) === 0;

  //   // Loop through each item
  //   console.log("this.form.orderItem",this.form.orderItem)
  //   this.form.orderItem.map((item, index) => {
  //     if (isDiscountRemoved) {
  //       // Revert to original total amount if discount is removed
  //       if (item.original_total_amt) {
  //         item.total_amt = item.original_total_amt;
  //         console.log("Total amount ad_disc removed", item.total_amt)
  //         item.ad_discount = 0;
  //         item.ad_discount_amt = 0;
  //         this.form.basicData.discount_amt -= item.ad_discount_amt; // Adjust basicData.discount_amt
  //         item.offer_price = item.original_offer_price
  //       }
  //     } else {
  //       // Apply the discount if it is present
  //       if (!item.original_total_amt) {
  //         item.original_total_amt = item.total_amt; // Store original total amount
  //       }

  //       let adDiscountPercentage = parseFloat(this.form.basicData.ad_discount) || 0;
  //       let adDiscountAmt = ((item.original_total_amt || 0) * adDiscountPercentage) / 100;
  //       this.adDiscountAmt += adDiscountAmt; // Accumulate the additional discount amount
  //       this.form.basicData.discount_amt = this.adDiscountAmt + parseFloat(this.form.basicData.discount_amt || '0');
  //       item.ad_discount = adDiscountPercentage;
  //       item.ad_discount_amt = adDiscountAmt;

  //       // Recalculate the total amount after applying ad_discount
  //       item.total_amt = (item.original_total_amt || 0) - adDiscountAmt;
  //       item.ad_total = item.total_amt;

  //       item.total_amt = parseFloat(item.total_amt).toFixed(2);
  //       this.form.basicData.total_amt = item.total_amt;
  //       console.log("Offer before apply ad discount",item.offer_price)

  //       item.offer_price -= item.ad_discount_amt/item.order_qty
  //       item.offer_price = parseFloat(item.offer_price).toFixed(2);
  //       console.log("Ad discount",item.ad_discount_amt)

  //       console.log("Offer after apply ad discount",item.offer_price)

  //       console.log("\n")

  //       item.savings = item.total_on_mrp - item.total_amt
  //       console.log("TOTAL AMT ON MRP:--", item.total_on_mrp)
  //       console.log("Total After AD-disc:--", item.total_amt)
  //       console.log("Savings after Apply Ad_Discount:--", item.savings)
  //     }
  //   });

  //   this.recalculateBasicData();
  // }

  recalculateBasicData() {
    console.log("Recalculating Basic Data...", this.form.orderItem);
    this.form.basicData.net_amt = 0;
    this.form.basicData.total_qty = 0;
    this.form.basicData.total_amt = 0;
    this.form.basicData.sub_total = 0;

    this.form.orderItem.forEach(item => {
      this.form.basicData.total_qty += parseInt(item.order_qty);
      this.form.basicData.net_amt += parseFloat(item.net_amt);
      this.form.basicData.total_amt += parseFloat(item.total_amt);
      this.form.basicData.sub_total += parseFloat(item.sub_total);
    });

    this.form.basicData.net_amt = parseFloat(this.form.basicData.net_amt).toFixed(2);
    this.form.basicData.total_amt = parseFloat(this.form.basicData.total_amt).toFixed(2);
    this.form.basicData.discount_amt = parseFloat(this.form.basicData.discount_amt).toFixed(2);
    this.form.basicData.sub_total = parseFloat(this.form.basicData.sub_total).toFixed(2);
    // console.log("Recalculated Basic Data:", this.form.basicData);
  }

}