import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-hsn-code-detail-model',
  templateUrl: './hsn-code-detail-model.component.html'
})

export class HsnCodeDetailModelComponent implements OnInit {
  gallery:any=[];
  // @Inject(MAT_DIALOG_DATA) public data: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}) {
    // setTimeout(() => {
      
    //   console.log(this.data);
    // }, 2000);
    
   }


  ngOnInit() {
    this.gallery = new Array(4);
  }

}
// function Inject(MD_DIALOG_DATA: any) {
//   throw new Error('Function not implemented.');
// }

// function MD_DIALOG_DATA(MD_DIALOG_DATA: any) {
//   throw new Error('Function not implemented.');
// }

