import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import { StatusModelComponent } from '../../status-model/status-model.component';
import {Location} from '@angular/common';
import { AddBonusModelComponent } from '../../add-bonus-points/add-bonus.component';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ContactEditModelComponent } from 'src/app/dr-network/contact-edit-model/contact-edit-model.component';
import  swal from 'sweetalert';

@Component({
  selector: 'app-dealer-basic',
  templateUrl: './dealer-basic.component.html'
})
export class DealerBasicComponent implements OnInit {
  
  constructor(public customService:CustomEventsService,public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { 
    // customService.subscribe('refresh-lead-detail',resp=>{
    //   this.getDetail(this.activatedroute.snapshot.params.id)
    // })
  }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  
  showLoading:any=false;
  editSalesUser:any=false
  usersAssigned:any=[];
  usersData:any=[]
  filteredUsersData:any=[]
  getUsers(){
    this.usersAssigned=[];
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.showLoading= true;
    
    console.log('this.data.salesUserDetail',this.data.salesUserDetail);
    
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/sales_user').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        
        console.log('this.data.salesUserDetail',this.data.salesUserDetail);
        
        setTimeout(async () => {
          await resp['result'].data.map(r=>{
            r.user_name = r.name
            r.user_id = r.id
          })
          
          this.usersData = resp['result'].data;
          this.filteredUsersData = this.usersData
          await this.usersData.map(r=>{
            let i = this.data.salesUserDetail.findIndex(row=>row.user_id == r.id)
            if(i!=-1){
              this.usersAssigned.push(parseInt(r.id))
            }
          })
          
          console.log('this.data.salesUserDetail',this.data.salesUserDetail);
          
          console.log(typeof(this.usersAssigned));
          this.usersAssigned = Object.assign([],this.usersAssigned);
          console.log('this.filteredUsersData',this.filteredUsersData);
          console.log('this.usersAssigned-->',this.usersAssigned)
          this.showLoading= false;
        }, 1000 * 2);
        
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  usersAssignedNew:any=[];
  saveUsers(){
    
    this.usersAssignedNew = [];
    console.log('--',this.usersAssigned)
    console.log('filteredUsersData', this.filteredUsersData);
    this.filteredUsersData.filter(x=>{
      var index = this.usersAssigned.findIndex(y=> y == x.id);
      console.log('index', index);
      if(index > -1){
        this.usersAssignedNew.push(x);
      }
    });
    console.log('usersAssignedNew', this.usersAssigned);
    // code end
    
    this.db.presentLoader();
    this.db.postReq({customer_id:this.data.basicDetail.id,userIds:this.usersAssignedNew},'dis_network/assign_sales_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.editSalesUser=false
        this.getDetail(this.activatedroute.snapshot.params.id);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['data']; 

        

      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  
  
  openDialog(): void {
    const dialogRef = this.dialog.open(StatusModelComponent, {
      width: '360px',
      autoFocus: false,
      data: {}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
  openEditContact(i): void {
    const dialogRef = this.dialog.open(ContactEditModelComponent, {
      width: '700px',
      autoFocus: false,
      data: this.data.contactDetail[i]
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.getDetail(this.activatedroute.snapshot.params.id)    });
    }
    addContact(): void {
      const dialogRef = this.dialog.open(ContactEditModelComponent, {
        width: '700px',
        autoFocus: false,
        data: {customer_id:this.activatedroute.snapshot.params.id}
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getDetail(this.activatedroute.snapshot.params.id)      });
      }
      contactDelete(data){
        swal({
          title: "Are you sure?",
          text: "You want delete this contact detail!" ,
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
        })
        .then((willDelete) => {        
          if (willDelete) {
            this.db.presentLoader();
            this.db.postReq({customer_id:data.customer_id,contact_id:data.id},'dis_network/delete_contact_detail').subscribe(resp=>{
              if(resp['status'] == 'success'){
                swal("Deleted!", {
                  icon: "success",
                });
                this.getDetail(this.activatedroute.snapshot.params.id)
                
              }else{
                if(resp['message']=='Invalid token'){
                  this.db.sessionExpire();
                  return;
                }
                this.db.presentAlert(resp['status'],resp['message'])
              }
            })
            
          } else {
            swal("Your data is safe!");
          }
        });
      }
      
      
    }
    