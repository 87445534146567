import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
// import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { MatDialog } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import * as moment from 'moment';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
import { ActivatedRoute } from '@angular/router';
// import { RemarkModelComponent } from '../../../remark-model/remark-model.component';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  statusData:any=[];
  tabActiveType:any={};
  
  constructor(public activatedRoute:ActivatedRoute,public event:CustomEventsService,private _location: Location, public dialog: MatDialog,public db:DatabaseService) 
  {
    this.getOrderStatus()
    this.filter.cust_type='';
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    
    this.event.subscribe('refresh-order-list', (data: any) => {
      console.log('refresh');
      
      this.start = 0;
      this.limit = 50;
      let order_status = this.filter.order_status; 
      this.filter={};
      this.filter.order_status = order_status;    
      this.getOrderStatus();
    })
  }
  
  ngOnInit() {
    
    
  }
  
  backClicked() {
    this._location.back();
  }
  
  
  tabActive(order_status:any)
  {
    // this.tabActiveType = {};
    // this.tabActiveType[tab] = true; 
    this.filter.order_status = order_status;
    this.start=0;
    this.limit=50;
    this.getData()
  }
  getOrderStatus(){
    
    this.db.presentLoader();
    this.db.postReq({type:this.activatedRoute.snapshot.params.type},'order/get_order_status').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.statusData = resp['result'].data;
        this.tabActive( resp['result'].data[0].name);
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  getData(){
    this.dataNotFound = false;
    this.reqSent = false;
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter,
      type:this.activatedRoute.snapshot.params.type
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/list').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == (this.start+this.data.length)) return;
      
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    let order_status = this.filter.order_status; 
    this.filter={};
    this.filter.order_status = order_status;    
    this.getData();
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want delete this order!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        data.order_id = data.id;
        this.db.postReq({order_id:data.id},'order/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal(data.status == 0 ? "Deleted!" : "Restored", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
  
  openStatusDialog(): void {
    // const dialogRef = this.dialog.open(PaymentStatusModelComponent, {
    //   width: '400px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
  openRemarkDialog(): void {
    // const dialogRef = this.dialog.open(RemarkModelComponent, {
    //   width: '450px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  detailData : any ={}
  detailDataItems :any =[]
  printDetail(id:any){
    this.db.presentLoader();
    
    this.db.postReq({'order_id':id},'order/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.detailData = resp['result'].data;   
        console.log("this.detailData",this.detailData)

        this.detailDataItems = resp['result'].orderItem;
        
        console.log("this.detailDataItems",this.detailDataItems)
        this.detailData = Object.assign({}, resp['result'].data);
        // this.detailData.subTotal = this.detailDataItems[0].sub_total;
        this.detailData.address = (this.detailData.area!=null ? this.detailData.area : '')  +' '+ (this.detailData.street!=null ? this.detailData.street : '') +' '+ (this.detailData.city!=null ? this.detailData.city : '') +' '+ (this.detailData.district_name!=null ? this.detailData.district_name : '') +' '+ (this.detailData.state!=null ? this.detailData.state : '');
        console.log("DetailData====",this.detailData);
        // console.log("DetailDataItem====>",this.detailDataItems);
        setTimeout(() => {
          this.print();
        }, 1000);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })


  }
  totalAmt(data:any){
    let amt = 0;
    for(let i = 0; i<=data.length-1; i++){
      amt = amt + this.detailDataItems[i].total_amt 
      amt = Math.round(amt);
    }
    // console.log("total_amt",amt)
    return amt;
  }

  savings(data:any){
    let sav = 0;
    for(let i = 0; i<=data.length-1; i++){
      sav = sav + this.detailDataItems[i].savings 
      sav = parseFloat(sav.toFixed(2))
    }
    // console.log("total_sav",sav)
    return sav;
  }

  convertNumberToWords(amount: number): string {
    if (isNaN(amount)) return ''; // Handle NaN
  
    const units: string[] = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens: string[] = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens: string[] = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    // Function to convert two digits
    function convertTens(num: number): string {
      if (num < 10) return units[num];
      else if (num >= 10 && num < 20) return teens[num - 10];
      else {
        const ten = Math.floor(num / 10);
        const unit = num % 10;
        return tens[ten] + (unit !== 0 ? ' ' + units[unit] : '');
      }
    }
  
    // Function to convert three digits
    function convertHundreds(num: number): string {
      const hundred = Math.floor(num / 100);
      const remainder = num % 100;
      let result = '';
      if (hundred > 0) {
        result += units[hundred] + ' Hundred';
        if (remainder > 0) result += ' and ';
      }
      if (remainder > 0) result += convertTens(remainder);
      return result;
    }
  
    // Function to convert number to words
    function convert(num: number): string {
      if (num === 0) return 'Zero';
      const crore = Math.floor(num / 10000000);
      const lakh = Math.floor((num % 10000000) / 100000);
      const thousand = Math.floor((num % 100000) / 1000);
      const remainder = num % 1000;
      let result = '';
  
      if (crore > 0) {
        result += convertHundreds(crore) + ' Crore ';
      }
      if (lakh > 0) {
        result += convertHundreds(lakh) + ' Lakh ';
      }
      if (thousand > 0) {
        result += convertHundreds(thousand) + ' Thousand ';
      }
      if (remainder > 0) {
        result += convertHundreds(remainder);
      }
  
      return result.trim();
    }
  
    // Convert amount to words
    const rupees = Math.floor(amount);
    const paise = Math.round((amount - rupees) * 100);
  
    let result = convert(rupees);
    if (paise > 0) {
      result += ' Rupees and ' + convert(paise) + ' Paise';
    } else {
      result += ' Rupees';
    }
  
    // Uppercase the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  

  

  // Example usage in template
  getTotalAmountInWords(): string {
    const totalAmount = this.totalAmt(this.detailDataItems); // Assuming this calculates total amount
    const amountInWords = this.convertNumberToWords(totalAmount);
  return amountInWords;
  }

  getTotalTaxInWords(): string {
    const totaltax = this.total_tax(this.detailDataItems)
    const taxInWords = this.convertNumberToWords(totaltax)
    // console.log("tax in words:-- ",taxInWords)
    return taxInWords;
  }

  getTotalSavingsInWords(): string {
    const totalsavings = this.savings(this.detailDataItems)
    const SavingsInWords = this.convertNumberToWords(totalsavings)
    // console.log("Savings in words:-- ",SavingsInWords)
    return SavingsInWords;
  }



  sgst_tax(data:any){
    let sgst = 0;
    for(let i = 0; i<=data.length-1; i++){
      sgst = sgst + this.detailDataItems[i].sgst_tax_amt
    }
    // console.log("total_sgst_tax",sgst)
    return sgst;
  }

  cgst_tax(data:any){
    let cgst = 0;
    for(let i = 0; i<=data.length-1; i++){
      cgst = cgst + this.detailDataItems[i].cgst_tax_amt
    }
    // console.log("total_cgst_tax",cgst)
    return cgst;
  }

  total_tax(data:any){
    let total_tax = 0;
    for(let i = 0; i<=data.length-1; i++){
      total_tax = total_tax + this.detailDataItems[i].total_tax
      total_tax = Math.floor(total_tax)
    }
    // console.log("total_tax",total_tax)
    return total_tax
  }
  
  print(){
    let prtContent = document.getElementById("invoice-detail");
    console.log('prtContent',prtContent);
    let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    console.log('WinPrint',WinPrint);
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  }
  
}
