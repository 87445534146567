import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import * as moment from 'moment';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
// import { type } from 'os';

@Component({
  selector: 'app-dr-add',
  templateUrl: './dr-add.component.html',
  // styleUrls: ['./dr-add.component.scss']
})
export class DrAddComponent implements OnInit {
  type:any = this.activatedroute.snapshot.params.type
  typeName:any = this.type == 1 ? 'Distributor' : 'Customer'
  
  constructor(public events:CustomEventsService,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDrType()
    this.db.getStates();
    this.getBrands();
    // this.getUsers();
    if(this.activatedroute.snapshot.params.id){
      this.getDetail(this.activatedroute.snapshot.params.id)
    }    else{
      this.formsInit({});
    } 
  }
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'customer_id':id},'dis_network/detail').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        
        this.formsInit(resp['data']);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  getDrType(){
    this.db.postReq({},'dis_network/type_list').subscribe(resp=>{
      this.db.drType = resp['result'].data
      this.db.drType.map(r=>r.id = r.id.toString())      
    })
  }
  
  
  form: FormGroup;
  
  formsInit(data) {
    this.form = this.db.formBuilder.group({
      company_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.company_name : null , [Validators.required]],
      first_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.first_name : null , [Validators.required]],
      // cust_code: [  null , []],
      // gst_no: [  null , [Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/),Validators.minLength(15)]],
      date_of_birth : [this.activatedroute.snapshot.params.id ? moment(data.basicDetail.date_of_birth).format('YYYY-MM-DD') : null   , []],
      date_of_anniversary  : [ this.activatedroute.snapshot.params.id ? moment(data.basicDetail.date_of_anniversary).format('YYYY-MM-DD') : null  ],
      
      cust_type_id: [  this.activatedroute.snapshot.params.id ? data.basicDetail.cust_type_id : null , [Validators.required]],
      cust_type: [  this.activatedroute.snapshot.params.id ? data.basicDetail.cust_type : null , []],
      status: [  'Pending' , [Validators.required]],
      email : [this.activatedroute.snapshot.params.id ? data.basicDetail.email : null , [Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
      mobile : [this.activatedroute.snapshot.params.id ? data.basicDetail.mobile : null   , [Validators.required,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
      landline_no: [  this.activatedroute.snapshot.params.id ? data.basicDetail.landline_no : null , []],
      // username: [  null , [Validators.required]],
      // password: [  null , [Validators.required]],
      // credit_day: [  null , [Validators.required]],
      street: [  this.activatedroute.snapshot.params.id ? data.basicDetail.street : null , []],
      state_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.state_name : null , []],
      district_name: [  this.activatedroute.snapshot.params.id ? data.basicDetail.district_name : null , []],
      city: [  this.activatedroute.snapshot.params.id ? data.basicDetail.city : null , []],
      zone: [  this.activatedroute.snapshot.params.id ? data.basicDetail.zone : null , []],
      area: [  this.activatedroute.snapshot.params.id ? data.basicDetail.area : null , []],
      pincode: [  this.activatedroute.snapshot.params.id ? data.basicDetail.pincode : null , []],
      cust_id : [this.activatedroute.snapshot.params.id ? this.activatedroute.snapshot.params.id : null],
      cp_email: [null],
      cp_designation: [null],
      cp_mobile: [null],
      cp_name: [null],
      contactDetail: [[] , []],
      // brandDetail: [[] , [Validators.required]],
      assignedUser: [[] , []],
    })
    
    this.getUsers(data);
      
    this.db.getDistricts(this.form.value.state_name);
    this.db.getCity(this.form.value.state_name,this.form.value.district_name);
    // this.db.getArea(this.form.value.state_name,this.form.value.district_name,this.form.value.city);
    // this.db.getBeat(this.form.value.state_name,this.form.value.district_name,this.form.value.city,this.form.value.area);

    if(!this.activatedroute.snapshot.params.id){ this.form.controls['cust_type_id'].setValue(this.type)} 
    
    
  }
  onSubmit(){
    console.log(this.form);
    if(this.form.invalid){
      this.form.markAllAsTouched();
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
          if (controls[name].invalid) {
              invalid.push(name);
          }
      }
      console.log('invalid',invalid);
      return invalid;
    }
    // return
    let reqData:any = {};
    this.form.value.date_of_birth = this.form.value.date_of_birth == 'Invalid date' ? '0000-00-00' : this.form.value.date_of_birth;
    this.form.value.date_of_anniversary = this.form.value.date_of_anniversary == 'Invalid date' ? '0000-00-00' : this.form.value.date_of_anniversary;
    reqData.basicDetail = this.form.value
    reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id == reqData.basicDetail.cust_type_id)[0].name
    reqData.contactDetail = this.form.value.contactDetail;
    // reqData.assignedUser = [{user_id:this.form.value.assignedUser.id,user_name:this.form.value.assignedUser.name
    // }];
    // reqData.brandDetail = this.form.value.brandDetail;
    // console.log(reqData);
    
    // if(this.form.value.contactDetail.length==0){
    //   this.db.presentAlert('Error','Please Enter atleast one contact detail')
    //   return;
    // }
    reqData.status = this.form.value.status
    this.db.presentLoader();
    this.db.postReq(reqData,this.activatedroute.snapshot.params.id  ? 'dis_network/update_network' : 'dis_network/add_network').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        // this.events.publish('tabActive',{})
        this.backClicked();
        this.db.router.navigateByUrl('dr-list/'+this.form.value.cust_type_id,{replaceUrl:true})
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  addContactDetails(){
    console.log(this.form.value,this.form.value.cp_name ,this.form.value.cp_designation ,this.form.value.cp_email ,this.form.value.cp_mobile)
    if(!this.form.value.cp_name || !this.form.value.cp_designation || !this.form.value.cp_email || !this.form.value.cp_mobile){
      this.db.presentAlert('Error','Please fill all the required details to continue!');
      return;
    }
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    console.log(emailpattern.test(this.form.value.cp_email));
    
    if(!emailpattern.test(this.form.value.cp_email)){
      this.db.presentAlert('Error','Please enter a valid email!');
      return;
    }
    var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    if(!mobilepattern.test(this.form.value.cp_mobile)){
      this.db.presentAlert('Error','Please enter a valid mobile!');
      return;
    }
    this.form.controls['contactDetail'].setValue([...this.form.value.contactDetail,{name:this.form.value.cp_name ,email:this.form.value.cp_email,designation:this.form.value.cp_designation,mobile:this.form.value.cp_mobile}])
    this.form.controls['cp_name'].setValue(null);
    this.form.controls['cp_designation'].setValue(null);
    this.form.controls['cp_mobile'].setValue(null);
    this.form.controls['cp_email'].setValue(null);
  }
  deleteContact(index){
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {  
        this.form.controls['contactDetail'].setValue(this.form.value.contactDetail.filter((r,i)=> i != index ))
      } else {
        swal("Your data is safe!");
      }
    });
    
  }
  brandsData:any=[]
  filteredbrandsData:any=[];
  getBrands(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/brand').subscribe(resp=>{
      if(resp['status'] == 'success'){
        resp['result'].data.map(r=>{
          this.brandsData.push({brand_name:r.name,brand_id:r.id})
          this.filteredbrandsData.push({brand_name:r.name,brand_id:r.id})
        });
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }

  usersData:any=[]
    filteredUsersData:any=[]
    getUsers(data:any={}){
      let reqData = {
        limit : 30000,
        start : 0,
        filter : {}
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/sales_user').subscribe(resp=>{
        if(resp['status'] == 'success'){
          this.usersData = resp['result'].data;
          this.filteredUsersData = this.usersData

          // console.log(data.salesUserDetail.length);
          if(data.salesUserDetail){
            data.salesUserDetail.map(r=>{
              let i = this.usersData.findIndex(item => item.id == r.user_id)
              if(i!= -1){
                this.form.controls['assignedUser'].setValue([...this.form.value.assignedUser,this.usersData[i]]);
              }
            })
          }
          

        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }

  postalData:any=[];
  pincodeHandler(){
    let reqData = {
      filter:{pincode:this.form.value.pincode}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/getDetailByPincode').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.postalData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  selectPostal(data){
    this.form.controls['state_name'].setValue(data.state_name);
    this.form.controls['district_name'].setValue(data.district_name);
    this.form.controls['city'].setValue(data.city);
    this.form.controls['area'].setValue(data.area);
    this.form.controls['zone'].setValue(data.zone);
    this.form.controls['beat'].setValue(data.beat);
  }
}
